import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    'zh-CN': require('./lang/zh'),
    'en': require('./lang/en'),
    // 'zh-TW': require('./lang/tw'),
    // 'ko': require('./lang/ko'),
    // 'es': require('./lang/es'),
    // 'de': require('./lang/de'),
    // 'fr': require('./lang/fr'),
    // 'ja': require('./lang/ja')
  }
})

export default i18n