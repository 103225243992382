import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/panel'
    },
    {
      path: '/login',
      name: 'login',
      component: resolve => require(['@/pages/login/login'], resolve),
    },
    {
      path: '/register',
      name: 'register',
      component: resolve => require(['@/pages/login/register'], resolve),
    },
    {
      path: '/panel',
      name: 'panel',
      redirect: '/panel/transaction',
      component: resolve => require(['@/pages/panel'], resolve),
      children: [
        {
          path: 'transaction',
          name: 'transaction',
          component: resolve => require(['@/pages/transaction/index'], resolve)
        },
        {
          path: 'robot',
          name: 'robot',
          component: resolve => require(['@/pages/robot/index'], resolve)
        },
        {
          path: 'user',
          name: 'user',
          redirect: '/panel/user/index',
          component: resolve => require(['@/pages/user/menu'], resolve),
          children: [
            {
              path: 'index',
              name: 'index',
              component: resolve => require(['@/pages/user/index'], resolve)
            },
            {
              path: 'recharge',
              name: 'recharge',
              component: resolve => require(['@/pages/user/recharge'], resolve)
            },
            {
              path: 'withdraw',
              name: 'withdraw',
              component: resolve => require(['@/pages/user/withdraw'], resolve)
            },
            {
              path: 'loan',
              name: 'loan',
              component: resolve => require(['@/pages/user/loan'], resolve)
            },
            {
              path: 'auth',
              name: 'auth',
              component: resolve => require(['@/pages/user/auth'], resolve)
            },
            {
              path: 'bank',
              name: 'bank',
              component: resolve => require(['@/pages/user/bank'], resolve)
            },
            {
              path: 'cashLog',
              name: 'cashLog',
              component: resolve => require(['@/pages/user/cashLog'], resolve)
            },
            {
              path: 'rechargeLog',
              name: 'rechargeLog',
              component: resolve => require(['@/pages/user/rechargeLog'], resolve)
            },
            {
              path: 'withdrawLog',
              name: 'withdrawLog',
              component: resolve => require(['@/pages/user/withdrawLog'], resolve)
            },
            {
              path: 'loanHistory',
              name: 'loanHistory',
              component: resolve => require(['@/pages/user/loanHistory'], resolve)
            },
            {
              path: 'loanRePayment',
              name: 'loanRePayment',
              component: resolve => require(['@/pages/user/loanRePayment'], resolve)
            },
            {
              path: 'changePassword',
              name: 'changePassword',
              component: resolve => require(['@/pages/user/changePassword'], resolve)
            },
          ]
        }
      ]
    }
  ]
});

// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => {
//     if (Router.isNavigationFailure(err, Router.NavigationFailureType.duplicated)) {
//       return err;
//     }
//     throw err;
//   });
// };

export default router;