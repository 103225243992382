import './rem'
import Vue from 'vue'
import i18n from './i18n'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import { formatNumber } from './utils'
import VueClipboard from 'vue-clipboard2'

import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI).use(VueClipboard)
Vue.filter('formatNumber', formatNumber)

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
