module.exports = {
  personal_center: {
    1: '个人中心',
    2: '行情',
    3: '持仓',
    4: '自选',
    5: '我的',
    6: '基本信息',
    7: '基金账户',
    8: '智能机器人',
    9: '开启',
    10: '关闭',
    11: '可用资金',
    12: '冻结资金',
    13: '盈亏',
    14: '账号',
    15: '总资金',
    16: '登出',
    17: '累计充值',
    18: '收益预计',
    19: '基金托管',
    20: '首页',
    21: '总收益'
  },
  stock: {
    1: '基金',
    2: '美股',
    3: '基金持仓',
    4: '基金平仓',
    5: '美股持仓',
    6: '美股平仓',
    7: '名称',
    8: '现价',
    9: '涨幅',
    10: '最高',
    11: '今开',
    12: '昨收',
    13: '最低',
    14: '分时',
    15: '日',
    16: '日线',
    17: '周线',
    18: '月线',
    19: '年线',
    20: '详情'
  },
  transaction: {
    1: '交易',
    2: '数量',
    3: '金额',
    4: '杠杆',
    5: '请选择',
    6: '止盈',
    7: '止损',
    8: '支付',
    9: '可用资金',
    10: '买入',
    11: '卖出',
    12: '请填写数量',
    13: '请填写金额',
    14: '请选择杠杆'
  },
  position: {
    1: '买入价格',
    2: '现价',
    3: '数量',
    4: '杠杆',
    5: '保证金',
    6: '手续费',
    7: '止盈',
    8: '止损',
    9: '盈亏',
    10: '订单号',
    11: '日期',
    12: '方向',
    13: '操作',
    14: '名称',
    15: '平仓',
    16: '确认要平仓吗?',
    17: '确认',
    18: '取消',
    19: '平仓价'
  },
  login: {
    1: '客户端登录',
    2: '邮箱',
    3: '密码',
    4: '请填写邮箱',
    5: '请填写密码',
    6: '提交',
    7: '注册',
    8: '登录',
    9: '验证码',
    10: '发送',
    11: '两次密码不一致',
    12: '密码长度不能小于6位',
    13: '请填写验证码',
    14: '手机号',
    15: '请填写手机号'
  },
  recharge: {
    1: '充值',
    2: '金额',
    3: '充值方式',
    4: '凭证',
    5: '备注',
    6: '提交',
    7: '请填写充值金额',
    8: '请上传凭证',
    9: '复制成功',
    10: '复制失败',
    11: '订单号',
    12: '日期',
    13: '状态',
    14: '待付款',
    15: '成功',
    16: '失败',
    17: '信息',
    18: '充值记录',
    19: '复制'
  },
  withdraw: {
    1: '提现',
    2: '金额',
    3: '提现方式',
    4: '姓名',
    5: '银行',
    6: '卡号',
    7: '类型',
    8: '地址',
    9: '提交',
    10: '请选择类型',
    11: '银行卡',
    12: '请填写地址',
    13: '请填写金额',
    14: '可提现余额',
    15: '全部',
    16: '手续费',
    17: '状态',
    18: '审核中',
    19: '成功',
    20: '失败',
    21: '申请日期',
    22: '信息',
    23: '提现记录'
  },
  auth: {
    1: '护照',
    2: '驾驶证',
    3: '证件照',
    4: '姓名',
    5: '证件号',
    6: '证件类型',
    7: '证件照',
    8: '请填写姓名',
    9: '请填写证件号',
    10: '请选择类型',
    11: '请上传证件照',
    12: '认证',
    13: '提交',
    14: '未认证',
    15: '审核中',
    16: '已认证',
    17: '认证失败',
    18: '状态'
  },
  bank: {
    1: '银行账户',
    2: '姓名',
    3: '银行',
    4: '卡号',
    5: '支行地址',
    6: '邮箱',
    7: '请填写姓名',
    8: '请填写银行',
    9: '请填写卡号',
    10: '请填写SSN',
    11: '请填写支行地址',
    12: '请填写邮箱',
    13: '提交',
    14: '状态',
    15: '未绑定'
  },
  cashLog: {
    1: '资金明细',
    2: '金额',
    3: '之前',
    4: '之后',
    5: '类型',
    6: '日期'
  },
  changePassword: {
    1: '更改密码',
    2: '旧密码',
    3: '新密码',
    4: '确认密码',
    5: '请填写旧密码',
    6: '请填写新密码',
    7: '提交'
  },
  loan: {
		1: '投资贷款',
		2: '贷款金额',
		3: '证件类型',
		4: '证件正面',
		5: '证件反面',
		6: '请输入贷款金额',
		7: '请选择证件类型',
		8: '请上传证件正面照',
		9: '请上传证件背面照',
		10: '注：有关贷款的详细信息请查看(服务条款)中的详细贷款条款，并遵守我们的服务条款。',
		11: '贷款历史',
		12: '申请',
		13: '还款',
		14: '审核中',
		15: '成功',
		16: '拒绝',
		17: '还款成功',
		18: '还款失败',
		19: '凭证',
		20: '请上传付款凭证',
		21: '申请日期',
		22: '还款日期',
		23: '状态',
		24: '投资贷款的还款期限最长为30天，您需要在此期限内偿还投资贷款，您可以在到期前购买比特币或者USDT来偿还投资贷款，我们不能从您的账户中扣除投资贷款，您是否同意此条款？',
		25: '货款审核中',
    26: '确认',
    27: '取消'
	},
  robot: {
    1: {
      title: '初级',
      desc: '市场触发买卖条件，AI自动交易系统果断交易'
    },
    2: {
      title: '中级',
      desc: 'AI投资交易利用高算力云电脑每分钟进行计算市场交易订单'
    },
    3: {
      title: '高级',
      desc: 'AI交易系统同时标定的所有基金市场，大大提高交易绩效'
    },
    4: {
      title: '特级',
      desc: 'AI交易模型仅对过去的价格发展进行统计，自动制定有效的应对策略，不断增加交易订单概率和收益概率'
    },
    5: {
      title: '顶级人工智能交易软件',
      desc: '附送人工智能交易软件，使用软件直观检测市场走势，一旦出现交易订单机会第一时间通知使用者，也可以自动进行订单交易，拥有更多的交易订单的机会'
    }
  },
  1: '加载中...',
  2: '暂无记录'
}