module.exports = {
  personal_center: {
    1: 'Personal Center',
    2: 'Markets',
    3: 'Positions',
    4: 'Optional',
    5: 'My',
    6: 'Basic Information',
    7: 'Fund Account',
    8: 'Intelligent Robot',
    9: 'Open',
    10: 'Close',
    11: 'Available',
    12: 'Freeze',
    13: 'Floating',
    14: 'Account',
    15: 'Total Funds',
    16: 'Logout',
    17: 'Accumulated recharge',
    18: 'Estimated income',
    19: 'Fund Custody',
    20: 'Home',
    21: 'Total Revenue'
  },
  stock: {
    1: 'Fund',
    2: 'US',
    3: 'Fund Positions',
    4: 'Fund Closes',
    5: 'US Positions',
    6: 'US Closes',
    7: 'Name',
    8: 'Last Price',
    9: 'Change',
    10: 'High',
    11: 'Open',
    12: 'Close',
    13: 'Low',
    14: 'Time',
    15: '5Day',
    16: 'Day',
    17: 'Week',
    18: 'Month',
    19: 'Year',
    20: 'Details'
  },
  transaction: {
    1: 'Trade',
    2: 'Quantity',
    3: 'Amount',
    4: 'Leverage',
    5: 'Please select',
    6: 'T/P',
    7: 'S/L',
    8: 'Payment',
    9: 'Available',
    10: 'Buy',
    11: 'Sell',
    12: 'Please fill in quantity',
    13: 'Please fill in amount',
    14: 'Please select leverage'
  },
  position: {
    1: 'Buy Price',
    2: 'Last Price',
    3: 'Quantity',
    4: 'Leverage',
    5: 'Margin',
    6: 'Fee',
    7: 'T/P',
    8: 'S/L',
    9: 'Floating',
    10: 'Order No',
    11: 'Date',
    12: 'Direction',
    13: 'Operation',
    14: 'Name',
    15: 'Close',
    16: 'Are you sure you want to close the position?',
    17: 'Confirm',
    18: 'Cancel',
    19: 'Close Price'
  },
  login: {
    1: 'Client login',
    2: 'Email',
    3: 'Password',
    4: 'Please fill in your email',
    5: 'Please fill in your password',
    6: 'Submit',
    7: 'Register',
    8: 'Login',
    9: 'Verification code',
    10: 'Send',
    11: 'The two passwords do not match',
    12: 'The password length cannot be less than 6 characters',
    13: 'Please fill in the verification code',
    14: 'Phone',
    15: 'Please fill in your Phone number'
  },
  recharge: {
    1: 'Deposit',
    2: 'Amount',
    3: 'Deposit method',
    4: 'Voucher',
    5: 'Remarks',
    6: 'Submit',
    7: 'Please fill in the Deposit amount',
    8: 'Please upload the voucher',
    9: 'Copy successfully',
    10: 'Copy failed',
    11: 'Order No',
    12: 'Date',
    13: 'Status',
    14: 'Waiting for payment',
    15: 'Success',
    16: 'Failed',
    17: 'Information',
    18: 'Deposit record',
    19: 'Copy'
  },
  withdraw: {
    1: 'Withdraw',
    2: 'Amount',
    3: 'Withdrawal method',
    4: 'Name',
    5: 'Bank',
    6: 'Card number',
    7: 'Type',
    8: 'Address',
    9: 'Submit',
    10: 'Please select type',
    11: 'Bank card',
    12: 'Please fill in the address',
    13: 'Please fill in the amount',
    14: 'Withdrawable balance',
    15: 'All',
    16: 'Handling fee',
    17: 'Status',
    18: 'Under review',
    19: 'Success',
    20: 'Failed',
    21: 'Date',
    22: 'Information',
    23: 'Withdrawal record'
  },
  auth: {
    1: 'Passport',
    2: 'Driver\'s license',
    3: 'ID photo',
    4: 'Name',
    5: 'ID number',
    6: 'ID type',
    7: 'ID photo',
    8: 'Please fill in your name',
    9: 'Please fill in your ID number',
    10: 'Please select type',
    11: 'Please upload your ID photo',
    12: 'Authentication',
    13: 'Submit',
    14: 'Unauthenticated',
    15: 'Under review',
    16: 'Authenticated',
    17: 'Authentication failed',
    18: 'Status'
  },
  bank: {
    1: 'Credit',
    2: 'Name',
    3: 'Bank',
    4: 'Card number',
    5: 'Branch address',
    6: 'Email',
    7: 'Please fill in your name',
    8: 'Please fill in your bank',
    9: 'Please fill in your card number',
    10: 'Please fill in your SSN',
    11: 'Please fill in your branch address',
    12: 'Please fill in your email',
    13: 'Submit',
    14: 'Status',
    15: 'Unbound'
  },
  cashLog: {
    1: 'Fund details',
    2: 'Amount',
    3: 'Before',
    4: 'After',
    5: 'Type',
    6: 'Date'
  },
  changePassword: {
    1: 'Change password',
    2: 'Old password',
    3: 'New password',
    4: 'Confirm password',
    5: 'Please enter your old password',
    6: 'Please enter your new password',
    7: 'Submit'
  },
  loan: {
    1: 'Investment loan',
    2: 'Loan amount',
    3: 'Type of certificate',
    4: 'Front side of the ID',
    5: 'Back side of the ID',
    6: 'Please enter the loan amount',
    7: 'Please select the ID type',
    8: 'Please upload the front photo of your ID',
    9: 'Please upload the back photo of your ID',
    10: 'Note: For detailed information about the loan, please refer to the detailed loan terms in (Terms of Service) and comply with our Terms of Service. ',
    11: 'Loan history',
    12: 'Apply',
    13: 'Repayment',
    14: 'Under review',
    15: 'Successful',
    16: 'Rejected',
    17: 'Repayment successful',
    18: 'Repayment failure',
    19: 'Voucher',
    20: 'Please upload payment voucher',
    21: 'Apply date',
    22: 'Repayment date',
    23: 'Status',
    24: 'The maximum repayment period for investment loans is 30 days. You need to repay the investment loan within this period. You can buy Bitcoin or USDT to repay the investment loan before the due date. We cannot deduct the investment loan from your account. Do you agree to this term?',
    25: 'Payment under review',
    26: 'Confirm',
    27: 'Cancel'
  },
  levelList: {
    1: {
      title: 'Primary',
      desc: 'When the market triggers buying and selling conditions, the AI ​​automatic trading system will trade decisively'
    },
    2: {
      title: 'Intermediate',
      desc: 'AI investment trading uses high-computing cloud computers to calculate market trading orders every minute'
    },
    3: {
      title: 'Advanced',
      desc: 'The AI ​​trading system simultaneously calibrates all fund markets, greatly improving trading performance'
    },
    4: {
      title: 'Special',
      desc: 'The AI ​​trading model only counts past price developments, automatically formulates effective response strategies, and continuously increases the probability of trading orders and profit probability'
    },
    5: {
      title: 'Top AI trading software',
      desc: 'Comes with AI trading software, which can be used to intuitively detect market trends, notify users immediately once a trading order opportunity appears, and can also automatically trade orders, giving users more opportunities to trade orders'
    }
  },
  1: 'Loading...',
  2: 'No records'
}